@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@500&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* SEARCH RESULT COMPONENTS */
.catSmallText {
    font-size: 14px;
}

.catBanner {
    background-color: #FFF;
    width: 100%;
    padding: 40px 0;
    margin-bottom: 40px;
}

/* FEATURED & SEARCH COMPONENT */
.feRoot {
    flex-grow: 1;
    margin-top: 50px;
    background-color: #F8F9FA;
    padding: 20px 0;
}

.feHeading {
    font-weight: 700;
    padding-bottom: 10px;
}

.fePaper {
    border-radius: 8px;
    padding: 18px;
    min-height: 85%;
    display: flex;
    flex-direction: column;
}

.feLink {
    float: right;
}

.feSection {
    margin-top: 5;
}

.feContent {
    display: flex;
    flex: 1 0 auto;
    align-items: flex-start;
    justify-content: center;
}

.feFooter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 16px;
}

.worldRoot {
    background-color: #F8F9FA;
}

.advChip {
    text-transform: capitalize;
    margin-right: 5px;
}

/* SEARCH BAR COMPONENT */

.sbcBtnContainer {
    margin-top: 10px;
    padding-right: 0;
    text-align: right;
}

.sbcTopSpacer {
    margin-top: 25px !important;
}

.sbcSearchBar {
    align-self: center;
    width: 50%;
    border-radius: 8px;
}

.sbcRoot {
    flex-grow: 1;
    margin: 70px 0;
}

.sbcFormCtrl {
    width: 100%;
}

.sbcContainer {
    margin: 20px 0;
}

@media screen and (max-width: 768px) {
    .sbcSearchBar {
        width: 70%;
    }
}

@media screen and (max-width: 480px) {
    .sbcSearchBar {
        width: 95%;
    }
}

/* NAVBAR */
.navRoot {
    flex-grow: 1;
}

.navIcon {
    margin-right: 5px;
    -webkit-filter: grayscale(100%);
            filter: grayscale(100%);
}

.navTitle {
    flex-grow: 1;
    text-align: left;
    color: #FFF;
    text-decoration: none;
    display: block;
}

.navAltTitle {
    flex-grow: 1;
    text-align: left;
    color: #FFF;
    text-decoration: none;
    display: none;
}

@media screen and (max-width: 599px) {
    .navTitle {
        display: none;
    }

    .navAltTitle {
        display: block;
    }

    .navSearchRoot {
        display: none !important;
    }
}

.navSearch {
    text-align: center;
    position: relative;
    border-radius: 8px;
    background-color: rgba(255, 255, 255, 0.15);
    margin-left: 0;
}

.navSearchRoot {
    color: inherit !important;
}

.navSearchInput {
    width: 18ch !important;
    padding: 8px 8px 8px 0px !important;
    transition: width 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
    /*vertical padding + font size from searchIcon*/
    padding-left: 1em !important;
}

.navSearchInput:focus {
    width: 28ch !important;
    border-radius: 8px !important;
    color: #777 !important;
    background: #FFF !important;
}

/* CATEGORY COMPONENT */
.catRoot {
    flex-grow: 1;
    margin-top: 50px;
}

.catChips {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.catChips * {
    margin: 1px;
}
